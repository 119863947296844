import React, { useState, useLayoutEffect, useRef, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import { RichText } from "prismic-reactjs"
import Fade from "react-reveal/Fade"
import Img from "gatsby-image"
import linkResolver from "src/utils/linkResolver"
import GatsbyLink from "src/utils/GatsbyLink"
import Slider from 'react-slick'

const CaseStudy = ({ data: { prismicCaseStudy, allPrismicCaseStudy } }) => {
  const { data } = prismicCaseStudy

  // Filter out non-null (for when the input is empty in prismic)
  const filteredDoorStyles = data.door_styles.filter(function (el) {
    return el.door_style.document != null;
  });
  //This will help us with the loop later
  const lastIndexDoorStyles = filteredDoorStyles.length - 1;

  // const door_styles = data.door_styles.map(item => {
  //   if (item.door_style.document) {
  //     return item.door_style.document.data?.title.text
  //   }
  //   return null
  // })

  const wood_colours = data.wood_colours.map(item => {
    if (item.wood_colour.document) {
      return item.wood_colour.document.data?.title.text
    }
    return null
  })

  const configurations = data.configurations.map(item => {
    if (item.configuration.document) {
      return item.configuration.document.data?.title.text
    }
    return null
  })

  const objectives =
    data.the_objectives.html !== "<p></p>" ? (
      <Fade>
        <div className="lg:w-6/12 w-full my-10 md:my-20">
          <h4 className="text-darkgrey font-bold text-xs tracking-widest uppercase">
            The Objectives
          </h4>
          <div className="mt-5">
            <RichText
              render={data.the_objectives.richText}
              serializeHyperlink={GatsbyLink}
            />
          </div>
        </div>
      </Fade>
    ) : (
      ""
    )

  const solution =
    data.deurens_solution.html !== "<p></p>" ? (
      <Fade>
        <div className="lg:w-6/12 w-full my-10 md:my-20 ml-auto">
          <h4 className="text-darkgrey font-bold text-xs tracking-widest uppercase">
            Deuren's Solution
          </h4>
          <div className="mt-5">
            <RichText
              render={data.deurens_solution.richText}
              serializeHyperlink={GatsbyLink}
            />
          </div>
        </div>
      </Fade>
    ) : (
      ""
    )

  const cost =
    data.project_cost.html !== "<p></p>" ? (
      <Fade>
        <div className="lg:w-6/12 w-full my-10 md:my-20">
          <h4 className="text-darkgrey font-bold text-xs tracking-widest uppercase">
            Project Cost
          </h4>
          <div className="mt-5">
            <RichText
              render={data.project_cost.richText}
              serializeHyperlink={GatsbyLink}
            />
          </div>
          <h4 className="text-darkgrey font-bold text-xs tracking-widest uppercase mt-5">
            Total: £{data.project_cost_value}
          </h4>
        </div>
      </Fade>
    ) : (
      ""
    )

  const quote = data.quote_title.text ? (
    <Fade>
      <div className="lg:w-6/12 w-full my-10 md:my-20">
        <h4 className="font-display text-gold rfs:text-4xl max-w-lg">
          {data.quote_title.text}
        </h4>
        <div className="w-32 separator mt-5"></div>
      </div>
    </Fade>
  ) : (
    ""
  )

  const images = data.images.map((item, index) => {
    if (item.image.fluid) {
      return (
        <Fade distance="20px" bottom>
          <Img
            fluid={item.image.fluid}
            alt={item.image.alt}
            className="object-cover h-full w-full min-h-full mb-3"
          />
        </Fade>
      )
    }
    return null
  })

  const images_second = data.images_second.map((item, index) => {
    if (item.image.fluid) {
      return (
        <Fade distance="20px" bottom>
          <Img fluid={item.image.fluid} alt={item.image.alt} />
        </Fade>
      )
    }
    return null
  })

  const images_third = data.images_third.map((item, index) => {
    if (item.image.fluid) {
      return (
        <Fade distance="20px" bottom>
          <Img fluid={item.image.fluid} alt={item.image.alt} />
        </Fade>
      )
    }
    return null
  })

  const readMoreItems = allPrismicCaseStudy.edges.map((item, index) => {
    return (
      <Link
        to={linkResolver(item.node)}
        key={index}
        className="group text-left border-l border-gold"
        title={`${item.node.data.title.text}`}
      >
        <Img
          className="-ml-px transition duration-500 ease-in-out group-hover:opacity-70"
          fluid={item.node.data.featured_image.fluid}
          alt={item.node.data.title.text}
        />
        <div className="p-6 pb-0">
          <h2 className="lg:h-8 text-darkgrey font-bold text-xs tracking-widest uppercase mb-6 mt-4 group-hover:text-gold duration-500 ease-in-out">
            {item.node.data.title.text}
          </h2>
          <RichText
            render={item.node.data.description.richText}
            serializeHyperlink={GatsbyLink}
          />
        </div>
      </Link>
    )
  })

  // https://codedaily.io/tutorials/79/Create-an-Animated-Circular-Progress-Indicator-to-Track-Article-Read-Percentage-in-React
  const [progress, setProgress] = useState(0)
  const articleRef = useRef()

  useLayoutEffect(() => {
    const updateHeight = () => {
      if (!articleRef.current) return
      const { height } = articleRef.current.getBoundingClientRect()
      setProgress(window.scrollY / (height + window.innerHeight))
    }

    updateHeight()

    window.addEventListener("scroll", updateHeight)
    return () => {
      window.removeEventListener("scroll", updateHeight)
    }
  })

  const position = Math.min(Math.max(progress * 100, 0), 100)

  // https://stackoverflow.com/questions/57453141/using-react-hooks-to-update-w-scroll
  const [scrolling, setScrolling] = useState(true)
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    let currentPosition = window.pageYOffset
    function onScroll() {
      currentPosition = window.pageYOffset
      if (window.scrollY > 200) {
        if (currentPosition > scrollTop) {
          setScrolling(false)
        } else {
          setScrolling(true)
        }
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition)
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  // const full = data.hero_image
  const desktop = data.hero_image.thumbnails.Desktop
  const tablet = data.hero_image.thumbnails.Tablet
  const mobile = data.hero_image.thumbnails.Mobile

  const sources = [
    mobile.fluid,
    {
      ...desktop.fluid,
      media: `(min-width: 1920px)`,
    },
    {
      ...tablet.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  const settings = {
    className: "w-full h-full overflow-hidden",
    infinite: true,
    slidesToShow: 1,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    swipeToSlide: true,
    focusOnSelect: true,
    fade: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    dots: true,
    dotsClass: "slick-dots",
  }  

  return (
    <article className="case-study">
      <Seo
        title={data.meta_title.text ? data.meta_title.text : data.title.text}
        description={
          data.meta_description.text
            ? data.meta_description.text
            : data.description.text
        }
        image={data.meta_image?.url ? data.meta_image.url : data.featured_image?.url}
        article={true}
      />
      <div
        className={`sticky-nav bg-white fixed w-full z-40 transition-all duration-500 ease-in-out hidden xl:block  ${
          scrolling ? "-top-16" : "top-0"
        }`}
      >
        <div className="px-14 py-4 flex justify-between">
          <div className="block relative items-center">
            <Link
              to="/inspiration/case-studies/"
              className="text-darkgrey font-bold text-xs tracking-widest uppercase mx-3 py-4 hover:text-gold duration-500 ease-in-out"
            >
              <i className="fas fa-chevron-left mr-2"></i>Back
            </Link>
          </div>
          <div className="reading">
            <span className="text-darkgrey font-bold text-xs tracking-widest uppercase mx-3 py-4">
              Reading:{" "}
            </span>{" "}
            <i>{data.title.text}</i>
          </div>
          <div className="social">
            <div className="text-left flex items-center">
              <h4 className="text-darkgrey font-bold text-xs tracking-widest uppercase mr-5">
                Share:{" "}
              </h4>
              <div className="space-x-4 text-base">
                <a
                  href={`https://twitter.com/share?url=https://deuren.co.uk/inspiration/case-study${prismicCaseStudy.url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fab fa-twitter text-darkgrey hover:text-gold transition duration-300 ease-in-out"
                    aria-label="Twitter"
                  ></i>
                </a>
                <a
                  href={`http://www.facebook.com/sharer.php?u=https://deuren.co.uk/inspiration/case-study${prismicCaseStudy.url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fab fa-facebook text-darkgrey hover:text-gold transition duration-300 ease-in-out"
                    aria-label="Facebook"
                  ></i>
                </a>
                <a
                  href={`mailto:?subject=${data.title.text}&body=Check out this Case Study on Deuren: https://deuren.co.uk/inspiration/case-study${prismicCaseStudy.url}`}
                >
                  <i
                    className="far fa-envelope text-darkgrey hover:text-gold transition duration-300 ease-in-out"
                    aria-label="Email"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-0.5 bg-grey top-full absolute">
          <div
            className="test bg-gold h-full"
            Style={`width: ${position}%`}
          ></div>
        </div>
      </div>
      { data.hero_carousel && data.hero_carousel.length > 0 && 
        <Slider {...settings}>
          {data.hero_carousel.map((item, index) => {       
            return (
              <div key={index}>
                <Img
                  fluid={item.image.fluid}
                  alt={item.image.alt}
                  className="object-cover w-full h-full"
                />
              </div>
            )
          })}
        </Slider>
      }
      { data.hero_carousel && data.hero_carousel.length === 0 &&
        <section className="bg-center flex flex-wrap content-center bg-cover">
          <Img
            className="object-cover w-full h-full"
            fluid={sources}
            alt={desktop.alt}
          />
        </section>
      }
      <main ref={articleRef} className={`bg-offwhite`}>
        <div className="container pt-12">
          <Fade>
            <Link
              to="/inspiration/case-studies/"
              className="text-darkgrey font-bold text-xs tracking-widest uppercase hover:text-gold duration-500 ease-in-out"
            >
              <i className="fas fa-chevron-left mr-2"></i>Back
            </Link>
          </Fade>
          <div
            className={`flex flex-col md:flex-row justify-between mb-10 mt-10`}
          >
            <div>
              <Fade>
                <h1 className="font-display text-gold rfs:text-4xl max-w-xl">
                  {data.title.text}
                </h1>
                <div className="w-32 separator mt-5"></div>
              </Fade>
            </div>
            <div className="mt-10 md:mt-0 space-y-4">
            {data.door_styles.filter(Boolean).join(", ") !== "" && (
              <div>
                <Fade>
                  <h4 className="text-darkgrey font-bold text-xs tracking-widest uppercase">
                    Door Style
                  </h4>
                  <p>
                  {filteredDoorStyles.map((item, i) => {
                    if (i === lastIndexDoorStyles) {
                      return ( // last one
                        <Link to={item.door_style.document.url}> {item.door_style.document.data?.title.text}</Link>
                      )
                    } else {
                      return ( // not last one
                        <>
                          <Link to={item.door_style.document.url}> {item.door_style.document.data?.title.text}</Link>, 
                        </>
                      )
                    }
                  })}
                  </p>
                </Fade>
              </div>
              )}
              {data.wood_colours.filter(Boolean).join(", ") !== "" && (
                <div>
                  <Fade>
                    <h4 className="text-darkgrey font-bold text-xs tracking-widest uppercase">
                      Wood/Colour
                    </h4>
                    <p>{wood_colours.filter(Boolean).join(", ")}</p>
                  </Fade>
                </div>
              )}
              {data.configurations.filter(Boolean).join(", ") !== "" && (
                <div>
                  <Fade>
                    <h4 className="text-darkgrey font-bold text-xs tracking-widest uppercase">
                      Configuration
                    </h4>
                    <p>{configurations.filter(Boolean).join(", ")}</p>
                  </Fade>
                </div>
              )}
            </div>
          </div>
          {objectives}
          <div className="case-study-images overflow-hidden flex space-x-3">
            <div className={`w-full lg:w-1/2`}>{images[0]}</div>
            <div className={`w-full lg:w-1/2 mb-2`}>
              {images[1]}
              <div style={{ marginTop: "-1px" }}>{images[2]}</div>
            </div>
          </div>
          {solution}
          <div className="case-study-images grid lg:grid-flow-col gap-4">
            {images_second}
          </div>
          {cost}
          <div className="case-study-images grid lg:grid-flow-col gap-4">
            {images_third}
          </div>
          {quote}
          <div className="text-center container">
            <Fade distance="20px" bottom>
              <h4 className="text-darkgrey font-bold text-xs tracking-widest uppercase mb-2">
                Share this
              </h4>
              <div className="space-x-4 rfs:text-xl">
                <a
                  href={`https://twitter.com/share?url=https://deuren.co.uk/inspiration/case-study${prismicCaseStudy.uid}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fab fa-twitter text-darkgrey hover:text-gold transition duration-300 ease-in-out"
                    aria-label="Twitter"
                  ></i>
                </a>
                <a
                  href={`http://www.facebook.com/sharer.php?u=https://deuren.co.uk/inspiration/case-study${prismicCaseStudy.uid}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fab fa-facebook text-darkgrey hover:text-gold transition duration-300 ease-in-out"
                    aria-label="Facebook"
                  ></i>
                </a>
                <a
                  href={`mailto:?subject=${data.title.text}&body=Check out this Case Study on Deuren: https://deuren.co.uk/inspiration/case-study${prismicCaseStudy.uid}`}
                >
                  <i
                    className="far fa-envelope text-darkgrey hover:text-gold transition duration-300 ease-in-out"
                    aria-label="Email"
                  ></i>
                </a>
              </div>
            </Fade>
          </div>
        </div>
      </main>
      <section className="bg-verylightgrey">
        <div className="container text-center">
          <Fade>
            <h2 className="font-display text-gold rfs:text-5xl">
              Get in Touch
            </h2>
            <div className="w-12 separator mt-5"></div>
            <p className="my-5">
              If you’d like to speak to us about a similar project,
              <br /> please get in touch with us.
            </p>
            <Link className="btn" to="/contact/">
              Contact the team
            </Link>
          </Fade>
        </div>
      </section>
      <section className="bg-offwhite">
        <div className="container text-center">
          <Fade>
            <h2 className="font-display text-gold rfs:text-5xl">Read More</h2>
            <div className="w-12 separator mt-5"></div>
            <p className="mt-5 mb-16">
              Delve further into stories of inspiring greatness.
            </p>
          </Fade>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {readMoreItems}
          </div>
        </div>
      </section>
    </article>
  )
}

export default CaseStudy

export const CaseStudyQuery = graphql`
  query CaseStudyBySlug($uid: String!, $uidd: [String]) {
    prismicCaseStudy(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_image {
          url
        }
        description {
          html
          richText
          text
        }
        featured_image {
          url
          alt
        }
        hero_image {
          url
          alt
          thumbnails {
            Desktop {
              url
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            Tablet {
              url
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            Mobile {
              alt
              url
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
        hero_carousel {
          image {
            url
            alt
            fluid {
              ...GatsbyPrismicImageFluid
            }
            thumbnails {
              Tablet {
                url
                alt
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              Mobile {
                alt
                url
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }        
        the_objectives {
          html
          richText
        }
        deurens_solution {
          html
          richText
        }
        project_cost {
          html
          richText
        }
        project_cost_value
        quote_title {
          text
        }
        images {
          image {
            url
            alt
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        images_second {
          image {
            url
            alt
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        images_third {
          image {
            url
            alt
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        configurations {
          configuration {
            document {
              ... on PrismicConfigurations {
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        door_styles {
          door_style {
            document {
              ... on PrismicInternalDoor {
                data {
                  title {
                    text
                  }
                }
                uid
              }
              ... on PrismicFrontDoor {
                data {
                  title {
                    text
                  }
                }
                uid
              }
              ... on PrismicGarageDoor {
                data {
                  title {
                    text
                  }
                }
                uid
              }
            }
          }
        }
        wood_colours {
          wood_colour {
            document {
              ... on PrismicWoodsAndFinishes {
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicCaseStudy(limit: 3, filter: { uid: { nin: $uidd } }) {
      edges {
        node {
          uid
          type
          data {
            title {
              text
            }
            featured_image {
              url
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            description {
              richText
            }
          }
        }
      }
    }
  }
`
